import api from '@/plugins/axios';

export const SetSeoTag = async (tag: string, value: string) => {
  await api.patch(`/analytics/seo/${tag}`, {
    value
  })
}

export const ListSeoTags = async () => {
  const { data } = await api.get(`/analytics/seo/list`);
  return data;
}

export const GetFactchecksWithoutSeoConfig = async () => {
  const { data } = await api.get(`/analytics/seo/factchecks-without-seo-data`);
  return data;
}

export const GenerateFactcheckSeoData = async (factcheckId: string) => {
  const { data } = await api.get(`/analytics/seo/generate-seo-data/${factcheckId}`);
  return data;
}