
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";

import * as SeoServices from '@/services/SeoServices';
import { Factcheck } from "@/types";

@Component({
  components: {
    EditFactcheckSeoDialog: () => import("@/components/dialogs/EditFactcheckSeoDialog.vue"),
  }
})
export default class SeoIndex extends Vue {

  factchecksWithoutSEO: Factcheck[] = [];

  selectedFactcheckId: string | null = null;

  loading = {
    factchecks: false,
    save: false,
  }


  dialogs = {
    editSeo: false,
  }
  
  editFactcheckSeo(factcheckId?: string) {
    if (!factcheckId) return console.error('No factcheckId provided')
    this.selectedFactcheckId = null;
    this.selectedFactcheckId = factcheckId;
    this.dialogs.editSeo = true;
  }

  async getFactchecksWithoutSeoConfig() {
    this.loading.factchecks = true;
    this.factchecksWithoutSEO = await SeoServices.GetFactchecksWithoutSeoConfig();
    this.loading.factchecks = false;
  }

  async mounted() {

    const list: any[] = await SeoServices.ListSeoTags();
    await this.getFactchecksWithoutSeoConfig()
    list.forEach(item => {
      Object.assign(this.inputs, {
        [item.id]: item.value
      })
    })
    this.$forceUpdate();
  }
  inputs: Record<string, string> = {}


  async saveTags() {
    try {
      this.loading.save = true;

      for (let key of Object.keys(this.inputs)) {
        await SeoServices.SetSeoTag(key, this.inputs[key])
      }
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Opgeslagen'
      })
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.save = false;
    }
  }
}
